<template>
  <el-tabs :type="tabType" v-model="activeName" :stretch="true" @tab-click="handleClick" :tab-position="tabPosition">
    <el-form @submit.prevent :model="$store.state.classForm" :rules="rules" ref="teacherClass_rules" :label-position="labelPosition">
      <el-tab-pane label="課程資訊" name="ClassInformation"><ClassInformation /></el-tab-pane>
      <el-tab-pane label="課程內容" name="ClassContent"><ClassContent /></el-tab-pane>
      <el-tab-pane label="課程費用" name="ClassPayment"><ClassPayment /></el-tab-pane>
    </el-form>
  </el-tabs>
  <ConfirmButtonGroup class="ConfirmButtonGroup" showButton="true" cancelText="取消" confirmText="確認" @cancel="cancel" @confirm="sendData" />
</template>

<script>
import ClassInformation from "./ClassInformation.vue";
import ClassContent from "./ClassContent.vue";
import ClassPayment from "./ClassPayment.vue";
import ConfirmButtonGroup from "../../../Common/ConfirmButtonGroup.vue";

export default {
  name: "AddClass",
  components: {
    ClassInformation,
    ClassContent,
    ClassPayment,
    ConfirmButtonGroup,
  },
  data() {
    const validateEmpty = (rule, value, callback) => {
      let val = value;
      if (rule.field == "hourlyFee") val = this.$store.state.classForm.price;
      if (rule.field == "teachingArea") val = this.toTurnArrayType(this.$store.state.classForm.regions);
      if (rule.field == "teachingType") val = this.toTurnArrayType(this.$store.state.classForm.teachingTypes);
      if (rule.field == "instrumentId") {
        val = this.toTurnInstrument(this.$store.state.classForm.instrumentId);
        if (val == 0) callback(new Error("此為必填資訊"));
      }
      if (val === "" || val === undefined || val.length === 0) {
        callback(new Error("此為必填資訊"));
      } else {
        callback();
      }
    };
    return {
      activeName: "ClassInformation",
      labelPosition: "top",
      rules: {
        name: [
          {
            validator: validateEmpty,
            trigger: "change",
          },
        ],
        status: [
          {
            validator: validateEmpty,
            trigger: "change",
          },
        ],
        degree: [
          {
            validator: validateEmpty,
            trigger: "change",
          },
        ],
        isExperience: [
          {
            validator: validateEmpty,
            trigger: "change",
          },
        ],
        instrumentId: [
          {
            validator: validateEmpty,
            trigger: "change",
          },
        ],
        teachingTypes: [
          {
            validator: validateEmpty,
            trigger: "change",
          },
        ],
        regions: [
          {
            validator: validateEmpty,
            trigger: "change",
          },
        ],
        hourlyFee: [
          {
            validator: validateEmpty,
            trigger: "change",
          },
        ],
        // dateRange: [
        //   {
        //     validator: validateEmpty,
        //     trigger: "change",
        //   },
        // ],
        // weekTime: [
        //   {
        //     validator: validateEmpty,
        //     trigger: "change",
        //   },
        // ],
        cover: [
          {
            validator: validateEmpty,
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    tabPosition() {
      return this.$store.getters.mobileSize ? "top" : "left";
    },
    tabType() {
      return this.$store.getters.mobileSize ? "card" : "";
    },
  },
  methods: {
    async cancel() {
      const vm = this;
      vm.activeName = "ClassInformation";
      vm.$refs["teacherClass_rules"].resetFields();
      vm.resetTeacherClassForm();
      await vm.$emit("close");
    },
    sendData() {
      const vm = this;
      vm.checkEditorBox();
      let temp_regions = vm.$store.state.classForm.regions;
      vm.$store.state.classForm.teachingTypes = vm.toTurnArrayType(vm.$store.state.classForm.teachingTypes);
      vm.$store.state.classForm.regions = vm.toTurnArrayType(vm.$store.state.classForm.regions);
      vm.$store.state.classForm.instrumentId = vm.toTurnInstrument(vm.$store.state.classForm.instrumentId);
      vm.$store.state.classForm.payMethod = vm.$store.state.classForm.payMethod.toString();
      if (vm.$store.state.classForm.dateRange.length == 2) {
        // console.log(vm.$store.state.classForm.dateRange);
        vm.$store.state.classForm.courseOpenedTimeInfo.startTime = vm.$store.state.classForm.dateRange[0];
        vm.$store.state.classForm.courseOpenedTimeInfo.endTime = vm.$store.state.classForm.dateRange[1];
      }
      vm.$store.state.classForm.courseOpenedTimeInfo.endTime;
      vm.$store.state.classForm.courseTimeEntities = vm.$store.state.classForm.courseTimeEntities.filter(x => x.courseId == vm.$store.state.classForm.courseId);
      vm.$store.state.classForm.color = vm.$store.state.classForm.color == null ? "#ffffff" : vm.$store.state.classForm.color;

      // 新增課程
      if (vm.$store.state.classForm.isAddNewClass) {
        vm.$refs["teacherClass_rules"].validate(valid => {
          if (valid) {
            vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/Courses`, vm.$store.state.classForm)
              .then(() => {
                vm.notify("success", "新增課程成功！");
                vm.$emit("rerender");
                vm.activeName = "ClassInformation";
                vm.$refs["teacherClass_rules"].resetFields();
                vm.resetTeacherClassForm();
                vm.$emit("close");
              })
              .catch(err => {
                console.log(err);
                vm.notify("error", "新增課程失敗！");
              });
          } else {
            vm.$store.state.classForm.regions = temp_regions;
            vm.notify("warning", "請確認必填資訊填寫完成");
          }
        });
      } else {
        vm.$refs["teacherClass_rules"].validate(valid => {
          if (valid) {
            vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/Courses/${vm.$store.state.classForm.courseId}`, vm.$store.state.classForm)
              .then(async () => {
                vm.notify("success", "更新課程成功！");
                vm.$emit("rerender");
                vm.activeName = "ClassInformation";
                vm.$refs["teacherClass_rules"].resetFields();
                vm.resetTeacherClassForm();
                await vm.$emit("close");
              })
              .catch(err => {
                console.log(err);
                vm.notify("error", "更新課程失敗！");
              });
          } else {
            vm.$store.state.classForm.regions = temp_regions;
            vm.notify("warning", "請確認必填資訊填寫完成");
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-tabs__header {
  position: fixed;
  top: 0;
}
.el-tabs--left {
  :deep .el-tabs__header.is-left {
    padding: 0 5rem 0;
    .el-tabs__item {
      margin: 2rem 0;
    }
  }
}
.ConfirmButtonGroup {
  z-index: 30000;
}
</style>
