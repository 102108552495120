<template>
  <div class="teacher_class_cal">
    <el-form-item prop="chargeMethod" label="模式選擇" class="chargeMethod">
      <el-radio-group v-model="method">
        <el-radio label="single">單周</el-radio>
        <el-radio label="cycle">循環</el-radio>
      </el-radio-group>
    </el-form-item>
    <div class="top_function_btn">
      <el-button @click="changeWeek('start')">本周</el-button>
      <el-button @click="changeWeek()">開始日</el-button>
      <el-button @click="changeWeek(-7)"><i class="fa-solid fa-angle-left"></i></el-button>
      <el-button @click="changeWeek(7)"><i class="fa-solid fa-angle-right"></i></el-button>
      <div class="date">
        <span>{{ startYear }}</span>
        <span>{{ startMonth }} / {{ startDay }}</span
        ><span>~</span><span v-if="endYear">{{ endYear }}</span
        ><span>{{ endMonth }} / {{ endDay }}</span>
      </div>
    </div>
    <div class="vws-table-rule">
      <div class="vws-table-rule-heading">
        <div class="vws-time-list vws-rule-time-time vws-time-rule opacity-0"></div>
        <div class="week-rule">全選星期</div>
        <div class="week-rule" v-for="(day, num) in dayTable" :key="num">
          {{ day.week }}
          <p class="mt-1">{{ day.dateShow }}</p>
        </div>
      </div>
      <div class="vws-table-rule-body">
        <div class="vws-rule-time" key="day">
          <div class="vws-time-list vws-rule-time-time vws-time-rule">全選時間</div>
          <div class="vws-time-list" />
          <div v-for="(day, num) in dayTable" :key="num" :class="{ 'vws-time-list vws-rule-time-item up': true, active: checkFullDay(num) }" @click="toggleFullDay(day, num, checkFullDay(num))" />
        </div>
        <div class="vws-rule-time time_list" v-for="(t, idx) in timeArray" :key="idx">
          <div class="vws-time-list vws-rule-time-time vws-time-rule" :data-val="t">{{ t }}</div>
          <div :class="{ 'vws-time-list vws-rule-time-week': true, active: checkFullWeek(idx) }" @click="toggleWeek(idx, checkFullWeek(idx))"></div>
          <div v-for="(day, num) in dayTable" :key="num" @click="toggleDay(num, idx)" :class="{ 'vws-time-list vws-rule-time-item': true, active: attributeCheck(mixTable[num], idx), default: attributeCheck(defaultTable[num], idx), outRange: outRangeCheck(day) }">
            <span> {{ getClassName(mixTable[num], idx) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const dayjs = require("dayjs");
var weekday = require("dayjs/plugin/weekday");
dayjs.extend(weekday);

export default {
  data() {
    return {
      method: "single",
      steps: 60,
      dayTable: [
        { week: "星期一", dateShow: "", year: "", month: "", day: "" },
        { week: "星期二", dateShow: "", year: "", month: "", day: "" },
        { week: "星期三", dateShow: "", year: "", month: "", day: "" },
        { week: "星期四", dateShow: "", year: "", month: "", day: "" },
        { week: "星期五", dateShow: "", year: "", month: "", day: "" },
        { week: "星期六", dateShow: "", year: "", month: "", day: "" },
        { week: "星期日", dateShow: "", year: "", month: "", day: "" },
      ],
      timeArrayLength: 24,
      timeArray: [],
      defaultTable: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      },
      mixTable: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      },
      weekNum: 0,
      startMonth: "",
      startDay: "",
      endMonth: "",
      endDay: "",
      startYear: "",
      endYear: "",
      today: null,
    };
  },
  created() {
    this.initData();
    this.today = dayjs(this.$store.state.classForm.dateRange[0]);
  },
  methods: {
    async initData() {
      const vm = this;
      if (vm.timeArray.length > 0) {
        vm.steps = 60;
        vm.timeArrayLength = vm.timeArray.length;
      }
      if (vm.timeArray.length == 0) {
        var times = [];
        var tt = 0;
        for (var i = 0; tt < 24 * 60; i++) {
          var hh = Math.floor(tt / 60) + 9;
          var mm = tt % 60;
          if (hh >= 24) hh -= 24;
          times[i] = ("0" + hh).slice(-2) + ":" + ("0" + mm).slice(-2);
          tt = tt + vm.steps;
        }
        vm.timeArray = times;
      }
      // 取得本周日期
      this.changeWeek();
    },
    turnThisWeek() {
      this.weekNum = 0;
      this.changeWeek();
    },
    async changeWeek(x) {
      const vm = this;
      vm.mixTable = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      };

      if (x == undefined || x == "start") vm.weekNum = 0;
      else vm.weekNum += x;

      if (x == "start") vm.today = dayjs();
      else if (x == undefined) vm.today = dayjs(vm.$store.state.classForm.dateRange[0]);

      var mon = vm.today.weekday(vm.weekNum + 1);
      var sun = vm.today.weekday(vm.weekNum + 7);

      vm.dayTable.forEach((x, i) => {
        var date = vm.today.weekday(vm.weekNum + i + 1);
        x.dateShow = `（${date.$M + 1} / ${date.$D}）`;
        x.year = date.$y;
        x.month = date.$M + 1;
        x.day = date.$D;
      });

      vm.startYear = mon.$y;
      vm.endYear = sun.$y;
      vm.startMonth = mon.$M + 1;
      vm.startDay = mon.$D;
      vm.endMonth = sun.$M + 1;
      vm.endDay = sun.$D;
      if (vm.startYear == vm.endYear) vm.endYear = "";

      // 初始化資料
      vm.defaultTable = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      };

      // 維持目前前後頁紀錄
      vm.$store.state.classForm.courseTimeEntities
        .filter(item => item.datetime >= mon && item.datetime < sun.add(1, "day"))
        .forEach(x => {
          if (x.courseId != vm.$store.state.classForm.courseId) {
            vm.defaultTable[x.dateIndex].push({ title: x.title, index: x.timeIndex });
            vm.mixTable[x.dateIndex].push({ title: x.title, index: x.timeIndex });
          } else {
            if (!x.isRemove) vm.mixTable[x.dateIndex].push({ title: "✓", index: x.timeIndex });
          }
        });

      // 抓日期區間外的課程
      var start = vm.$store.state.classForm.dateRange[0];
      var end = vm.$store.state.classForm.dateRange[1];
      if (sun.$d < start || mon.$d > end || (mon.$d < start && sun.$d > start) || (mon.$d < end && sun.$d > end)) {
        if (mon.$d < start && sun.$d > start) sun = dayjs(start).subtract(1, "day");
        if (mon.$d < end && sun.$d > end) mon = dayjs(end).add(1, "day");
        let sd = mon.format("YYYY-MM-DD");
        let ed = sun.format("YYYY-MM-DD");
        await vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/teacher/${vm.$store.state.classForm.teacherId}/calendar?startDate=${sd}&endDate=${ed}`).then(res => {
          // console.log(res.data);
          res.data.forEach(x => {
            if (x.id != vm.$store.state.classForm.courseId) {
              vm.defaultTable[x.dateIndex].push({ title: x.title, index: x.timeIndex });
              vm.mixTable[x.dateIndex].push({ title: x.title, index: x.timeIndex });
            }
          });
        });
      }
    },
    getClassName(x, idx) {
      if (x.length == 0) return;
      if (x.find(y => y.index == idx) != undefined) return x.find(y => y.index == idx).title;
    },
    toggleFullDay(date, day, status) {
      const vm = this;
      // 單周模式
      if (vm.method == "single") {
        for (let t = 0; t < vm.timeArrayLength / (vm.steps / 60); t++) {
          var dayCurrent = new Date(date.year, date.month - 1, date.day);
          if (dayCurrent < vm.$store.state.classForm.dateRange[0] || dayCurrent > vm.$store.state.classForm.dateRange[1]) continue;

          let defaultDay = vm.defaultTable[day].findIndex(el => el.index == t);
          if (defaultDay != -1) continue;

          let indexDay = vm.mixTable[day].findIndex(el => el.index == t);
          var newDate = vm.dayTable[day];
          var newTime = vm.timeArray[t];
          var newDateTime = new Date(newDate.year, newDate.month - 1, newDate.day, newTime.split(":")[0], newTime.split(":")[1]);
          if (indexDay != -1) {
            if (status) {
              vm.mixTable[day].splice(indexDay, 1);
              vm.$store.state.classForm.courseTimeEntities.find(item => item.datetime.toString() == newDateTime.toString()).isRemove = true;
            }
          } else {
            vm.mixTable[day].push({ title: "✓", index: t });
            if (vm.$store.state.classForm.courseTimeEntities.find(y => y.datetime.toString() == newDateTime.toString()) == undefined) {
              vm.$store.state.classForm.courseTimeEntities.push({
                title: "✓",
                teacherId: vm.$store.state.classForm.teacherId,
                courseId: vm.$store.state.classForm.courseId,
                courseTimeId: null,
                dateIndex: day,
                timeIndex: t,
                datetime: newDateTime,
                isRemove: false,
              });
            } else {
              vm.$store.state.classForm.courseTimeEntities.find(item => item.datetime.toString() == newDateTime.toString()).isRemove = false;
            }
          }
        }
      }

      // 循環模式
      let isPush = false;

      for (let t = 0; t < vm.timeArrayLength / (vm.steps / 60); t++) {
        let dayCurrent = new Date(date.year, date.month - 1, date.day);
        let defaultDay = vm.defaultTable[day].findIndex(el => el.index == t);
        let indexDay = vm.mixTable[day].findIndex(el => el.index == t);

        if (dayCurrent < vm.$store.state.classForm.dateRange[0] || dayCurrent > vm.$store.state.classForm.dateRange[1]) continue;
        if (defaultDay != -1) continue;
        if (indexDay == -1) isPush = true;
      }

      if (vm.method == "cycle") {
        let today = new Date(date.year, date.month - 1, date.day);
        if (isPush && vm.$store.state.classForm.courseTimeEntities.find(item => item.dateIndex == day && item.courseId != vm.$store.state.classForm.courseId && (item.datetime < today || item.datetime >= dayjs(today).add(1, "day").$d)) != undefined) {
          var item = vm.$store.state.classForm.courseTimeEntities
            .filter(item => item.dateIndex == day && item.courseId != vm.$store.state.classForm.courseId && (item.datetime < today || item.datetime >= dayjs(today).add(1, "day").$d))
            .sort((a, b) => {
              return a.datetime - b.datetime;
            });

          vm.notify("warning", "此循環時段已有其他課程，將取消動作，請各別點選！");
          for (let i = 0; i < item.length; i++) {
            if (i >= 5) break;
            window.setTimeout(() => {
              vm.$notify({
                title: `循環課程衝突，共${item.length}筆 - 第${i + 1}筆`,
                message: `名稱：${item[i].title} / 時間：${dayjs(item[i].datetime).format("YYYY/MM/DD - HH:mm")}`,
                type: "warning",
                duration: 5000,
                position: "top-right",
              });
            }, 500);
          }
        } else {
          if (!isPush) {
            vm.$confirm(`將循環取消每個${vm.dayTable[day].week}時段，請確認`, "Warning", {
              confirmButtonText: "確認",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                vm.handleToggleFullDay(date, day, status, isPush);
                vm.$message({
                  type: "success",
                  message: `已取消每個${vm.dayTable[day].week}時段`,
                });
              })
              .catch(() => {
                return;
              });
          } else {
            vm.handleToggleFullDay(date, day, status, isPush);
          }
        }
      }
    },
    handleToggleFullDay(date, day, status, isPush) {
      const vm = this;
      let dayNow = dayjs(vm.$store.state.classForm.dateRange[0]).weekday(day + 1).$d;
      if (dayNow < vm.$store.state.classForm.dateRange[0]) dayNow = dayjs(dayNow).add(7, "day").$d;

      for (let t = 0; t < vm.timeArrayLength / (vm.steps / 60); t++) {
        let dayCurrent = new Date(date.year, date.month - 1, date.day);
        let defaultDay = vm.defaultTable[day].findIndex(el => el.index == t);
        let indexDay = vm.mixTable[day].findIndex(el => el.index == t);

        if (dayCurrent < vm.$store.state.classForm.dateRange[0] || dayCurrent > vm.$store.state.classForm.dateRange[1]) continue;
        if (defaultDay != -1) continue;
        if (indexDay != -1) {
          if (status) {
            vm.mixTable[day].splice(indexDay, 1);
          }
        } else {
          vm.mixTable[day].push({ title: "✓", index: t });
        }
      }

      while (dayNow >= vm.$store.state.classForm.dateRange[0] && dayNow <= vm.$store.state.classForm.dateRange[1]) {
        for (let t = 0; t < vm.timeArrayLength / (vm.steps / 60); t++) {
          let dayCurrent = dayjs(dayNow).add(vm.timeArray[t].split(":")[0], "hour").$d;
          if (!isPush) {
            if (vm.$store.state.classForm.courseTimeEntities.find(item => item.datetime.toString() == dayCurrent.toString() && item.courseId == vm.$store.state.classForm.courseId) != undefined) {
              vm.$store.state.classForm.courseTimeEntities.find(item => item.datetime.toString() == dayCurrent.toString() && item.courseId == vm.$store.state.classForm.courseId).isRemove = true;
            }
          } else {
            if (vm.$store.state.classForm.courseTimeEntities.find(item => item.datetime.toString() == dayCurrent.toString() && item.courseId == vm.$store.state.classForm.courseId) == undefined) {
              vm.$store.state.classForm.courseTimeEntities.push({
                title: "✓",
                teacherId: vm.$store.state.classForm.teacherId,
                courseId: vm.$store.state.classForm.courseId,
                courseTimeId: null,
                dateIndex: day,
                timeIndex: t,
                datetime: dayCurrent,
                isRemove: false,
              });
            } else vm.$store.state.classForm.courseTimeEntities.find(item => item.datetime.toString() == dayCurrent.toString() && item.courseId == vm.$store.state.classForm.courseId).isRemove = false;
          }
        }
        dayNow = dayjs(dayNow).add(7, "day").$d;
      }
    },
    toggleWeek(time, status) {
      const vm = this;
      // 單周模式
      if (vm.method == "single") {
        for (const key in [0, 1, 2, 3, 4, 5, 6]) {
          let dayCurrent = new Date(vm.dayTable[key].year, vm.dayTable[key].month - 1, vm.dayTable[key].day);
          let defaultDay = vm.defaultTable[key].findIndex(el => el.index == time);
          let indexDay = vm.mixTable[key].findIndex(el => el.index == time);
          let newDate = vm.dayTable[Number(key)];
          let newTime = vm.timeArray[time];
          let newDateTime = new Date(newDate.year, newDate.month - 1, newDate.day, newTime.split(":")[0], newTime.split(":")[1]);

          if (dayCurrent < vm.$store.state.classForm.dateRange[0] || dayCurrent > vm.$store.state.classForm.dateRange[1]) continue;
          if (defaultDay != -1) continue;
          if (indexDay != -1) {
            if (status) {
              vm.mixTable[key].splice(indexDay, 1);
              vm.$store.state.classForm.courseTimeEntities.find(item => item.datetime.toString() == newDateTime.toString()).isRemove = true;
            }
          } else {
            vm.mixTable[key].push({ title: "✓", index: time });
            if (vm.$store.state.classForm.courseTimeEntities.find(y => y.datetime.toString() == newDateTime.toString()) == undefined) {
              vm.$store.state.classForm.courseTimeEntities.push({
                title: "✓",
                teacherId: vm.$store.state.classForm.teacherId,
                courseId: vm.$store.state.classForm.courseId,
                courseTimeId: null,
                dateIndex: Number(key),
                timeIndex: time,
                datetime: newDateTime,
                isRemove: false,
              });
            } else vm.$store.state.classForm.courseTimeEntities.find(item => item.datetime.toString() == newDateTime.toString()).isRemove = false;
          }
        }
      }

      // 循環模式
      let isPush = false;

      for (const key in [0, 1, 2, 3, 4, 5, 6]) {
        let dayCurrent = new Date(vm.dayTable[key].year, vm.dayTable[key].month - 1, vm.dayTable[key].day);
        let defaultDay = vm.defaultTable[key].findIndex(el => el.index == time);
        let indexDay = vm.mixTable[key].findIndex(el => el.index == time);

        if (dayCurrent < vm.$store.state.classForm.dateRange[0] || dayCurrent > vm.$store.state.classForm.dateRange[1]) continue;
        if (defaultDay != -1) continue;
        if (indexDay == -1) isPush = true;
      }

      if (vm.method == "cycle") {
        let mon = new Date(vm.dayTable[0].year, vm.dayTable[0].month - 1, vm.dayTable[0].day);
        let sun = new Date(vm.dayTable[6].year, vm.dayTable[6].month - 1, vm.dayTable[6].day);
        if (isPush && vm.$store.state.classForm.courseTimeEntities.find(item => item.timeIndex == time && item.courseId != vm.$store.state.classForm.courseId && (item.datetime < mon || item.datetime >= dayjs(sun).add(1, "day").$d)) != undefined) {
          var item = vm.$store.state.classForm.courseTimeEntities
            .filter(item => item.timeIndex == time && item.courseId != vm.$store.state.classForm.courseId && (item.datetime < mon || item.datetime >= dayjs(sun).add(1, "day").$d))
            .sort((a, b) => {
              return a.datetime - b.datetime;
            });

          vm.notify("warning", "此循環時段已有其他課程，將取消動作，請各別點選！");
          for (let i = 0; i < item.length; i++) {
            if (i >= 5) break;
            window.setTimeout(() => {
              vm.$notify({
                title: `循環課程衝突，共${item.length}筆 - 第${i + 1}筆`,
                message: `名稱：${item[i].title} / 時間：${dayjs(item[i].datetime).format("YYYY/MM/DD - HH:mm")}`,
                type: "warning",
                duration: 5000,
                position: "top-right",
              });
            }, 500);
          }
        } else {
          if (!isPush) {
            vm.$confirm(`將循環取消每週${vm.timeArray[time]}時段，請確認`, "Warning", {
              confirmButtonText: "確認",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                vm.handleToggleWeek(time, status, isPush);
                vm.$message({
                  type: "success",
                  message: `已取消每週${vm.timeArray[time]}時段`,
                });
              })
              .catch(() => {
                return;
              });
          } else {
            vm.handleToggleWeek(time, status, isPush);
          }
        }
      }
    },
    handleToggleWeek(time, status, isPush) {
      const vm = this;
      let dayNow = dayjs(vm.$store.state.classForm.dateRange[0]).add(vm.timeArray[time].split(":")[0], "hour").$d;

      for (const key in [0, 1, 2, 3, 4, 5, 6]) {
        let dayCurrent = new Date(vm.dayTable[key].year, vm.dayTable[key].month - 1, vm.dayTable[key].day);
        let defaultDay = vm.defaultTable[key].findIndex(el => el.index == time);
        let indexDay = vm.mixTable[key].findIndex(el => el.index == time);

        if (dayCurrent < vm.$store.state.classForm.dateRange[0] || dayCurrent > vm.$store.state.classForm.dateRange[1]) continue;
        if (defaultDay != -1) continue;
        if (indexDay != -1) {
          if (status) {
            vm.mixTable[key].splice(indexDay, 1);
          }
        } else {
          vm.mixTable[key].push({ title: "✓", index: time });
        }
      }

      while (dayNow < dayjs(vm.$store.state.classForm.dateRange[1]).add(1, "day").$d) {
        if (!isPush) {
          if (vm.$store.state.classForm.courseTimeEntities.find(item => item.datetime.toString() == dayNow.toString() && item.courseId == vm.$store.state.classForm.courseId) != undefined) {
            vm.$store.state.classForm.courseTimeEntities.find(item => item.datetime.toString() == dayNow.toString() && item.courseId == vm.$store.state.classForm.courseId).isRemove = true;
          }
        } else {
          if (vm.$store.state.classForm.courseTimeEntities.find(item => item.datetime.toString() == dayNow.toString() && item.courseId == vm.$store.state.classForm.courseId) == undefined) {
            vm.$store.state.classForm.courseTimeEntities.push({
              title: "✓",
              teacherId: vm.$store.state.classForm.teacherId,
              courseId: vm.$store.state.classForm.courseId,
              courseTimeId: null,
              dateIndex: dayjs(dayNow).$W == 0 ? 6 : dayjs(dayNow).$W - 1,
              timeIndex: time,
              datetime: dayNow,
              isRemove: false,
            });
          } else vm.$store.state.classForm.courseTimeEntities.find(item => item.datetime.toString() == dayNow.toString() && item.courseId == vm.$store.state.classForm.courseId).isRemove = false;
        }

        dayNow = dayjs(dayNow).add(1, "day").$d;
      }
    },
    toggleDay(day, time) {
      const vm = this;
      let indexDay = vm.mixTable[day].findIndex(el => el.index == time);
      let newDate = vm.dayTable[day];
      let newTime = vm.timeArray[time];
      let newDateTime = new Date(newDate.year, newDate.month - 1, newDate.day, newTime.split(":")[0], newTime.split(":")[1]);

      // 單周模式
      if (vm.method == "single") {
        if (indexDay != -1) {
          vm.mixTable[day].splice(indexDay, 1);
          vm.$store.state.classForm.courseTimeEntities.find(item => item.datetime.toString() == newDateTime.toString()).isRemove = true;
        } else {
          vm.mixTable[day].push({ title: "✓", index: time });
          if (vm.$store.state.classForm.courseTimeEntities.find(y => y.datetime.toString() == newDateTime.toString()) == undefined) {
            vm.$store.state.classForm.courseTimeEntities.push({
              title: "✓",
              teacherId: vm.$store.state.classForm.teacherId,
              courseId: vm.$store.state.classForm.courseId,
              courseTimeId: null,
              dateIndex: day,
              timeIndex: time,
              datetime: newDateTime,
              isRemove: false,
            });
          } else vm.$store.state.classForm.courseTimeEntities.find(item => item.datetime.toString() == newDateTime.toString()).isRemove = false;
        }
      }

      // 循環模式
      let isPush = false;
      if (indexDay == -1) isPush = true;

      if (vm.method == "cycle") {
        if (isPush && vm.$store.state.classForm.courseTimeEntities.find(item => item.timeIndex == time && item.dateIndex == day && item.courseId != vm.$store.state.classForm.courseId) != undefined) {
          var item = vm.$store.state.classForm.courseTimeEntities
            .filter(item => item.timeIndex == time && item.dateIndex == day && item.courseId != vm.$store.state.classForm.courseId)
            .sort((a, b) => {
              return a.datetime - b.datetime;
            });

          vm.notify("warning", "此循環時段已有其他課程，將取消動作，請各別點選！");
          for (let i = 0; i < item.length; i++) {
            if (i >= 5) break;
            window.setTimeout(() => {
              vm.$notify({
                title: `循環課程衝突，共${item.length}筆 - 第${i + 1}筆`,
                message: `名稱：${item[i].title} / 時間：${dayjs(item[i].datetime).format("YYYY/MM/DD - HH:mm")}`,
                type: "warning",
                duration: 5000,
                position: "top-right",
              });
            }, 500);
          }
        } else {
          if (!isPush) {
            vm.$confirm(`將循環取消每個${vm.dayTable[day].week}-${vm.timeArray[time]}時段，請確認`, "Warning", {
              confirmButtonText: "確認",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                vm.handleToggleDay(day, time);
                vm.$message({
                  type: "success",
                  message: `已取消每個${vm.dayTable[day].week}-${vm.timeArray[time]}時段`,
                });
              })
              .catch(() => {
                return;
              });
          } else {
            vm.handleToggleDay(day, time);
          }
        }
      }
    },
    handleToggleDay(day, time) {
      const vm = this;
      let mixTableSplice = true;
      let mixTablePush = true;
      let indexDay = vm.mixTable[day].findIndex(el => el.index == time);
      let dayCurrent = dayjs(vm.$store.state.classForm.dateRange[0])
        .add(vm.timeArray[time].split(":")[0], "hour")
        .weekday(day + 1).$d;
      if (dayCurrent < vm.$store.state.classForm.dateRange[0]) dayCurrent = dayjs(dayCurrent).add(7, "day").$d;

      while (dayCurrent >= vm.$store.state.classForm.dateRange[0] && dayCurrent <= vm.$store.state.classForm.dateRange[1]) {
        if (indexDay != -1) {
          if (mixTableSplice) {
            vm.mixTable[day].splice(indexDay, 1);
            mixTableSplice = false;
          }
          if (vm.$store.state.classForm.courseTimeEntities.find(item => item.datetime.toString() == dayCurrent.toString() && item.courseId == vm.$store.state.classForm.courseId) != undefined) {
            vm.$store.state.classForm.courseTimeEntities.find(item => item.datetime.toString() == dayCurrent.toString() && item.courseId == vm.$store.state.classForm.courseId).isRemove = true;
          }
        } else {
          if (mixTablePush) {
            vm.mixTable[day].push({ title: "✓", index: time });
            mixTablePush = false;
          }
          if (vm.$store.state.classForm.courseTimeEntities.find(item => item.datetime.toString() == dayCurrent.toString() && item.courseId == vm.$store.state.classForm.courseId) == undefined) {
            vm.$store.state.classForm.courseTimeEntities.push({
              title: "✓",
              teacherId: vm.$store.state.classForm.teacherId,
              courseId: vm.$store.state.classForm.courseId,
              courseTimeId: null,
              dateIndex: day,
              timeIndex: time,
              datetime: dayCurrent,
              isRemove: false,
            });
          } else vm.$store.state.classForm.courseTimeEntities.find(item => item.datetime.toString() == dayCurrent.toString() && item.courseId == vm.$store.state.classForm.courseId).isRemove = false;
        }
        dayCurrent = dayjs(dayCurrent).add(7, "day").$d;
      }
    },
    checkFullWeek(time) {
      let light = 0;
      for (const key in [0, 1, 2, 3, 4, 5, 6]) {
        var dayCurrent = new Date(this.dayTable[key].year, this.dayTable[key].month - 1, this.dayTable[key].day);
        if (dayCurrent < this.$store.state.classForm.dateRange[0] || dayCurrent > this.$store.state.classForm.dateRange[1]) {
          light += 1;
          continue;
        }
        if (this.mixTable[key].find(el => el.index == time) == undefined) return false;
      }
      if (light == 7) return false;
      return true;
    },
    checkFullDay(day) {
      for (let t = 0; t < this.timeArrayLength / (this.steps / 60); t++) {
        if (this.mixTable[day].find(el => el.index == t) == undefined) return false;
      }
      return true;
    },
    attributeCheck(timeTable, idx) {
      return timeTable.find(el => el.index == idx) != undefined ? true : false;
    },
    outRangeCheck(date) {
      var day = new Date(date.year, date.month - 1, date.day);
      if (day < this.$store.state.classForm.dateRange[0] || day > this.$store.state.classForm.dateRange[1]) return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.teacher_class_cal {
  border: 1px solid #9d9d9d;
  padding: 1rem;
  border-radius: 1rem;
  width: 80%;
  .top_function_btn {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    .date {
      margin-left: 1rem;
      span {
        font-weight: 700;
        font-size: 1.2rem;
        margin: 0 0.5rem;
      }
    }
  }
  .vws-table-rule-heading,
  .vws-rule-time {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
  }
  .vws-table-rule-heading div,
  .vws-rule-time .vws-time-list {
    flex-basis: 0;
    flex-grow: 1;
    margin: 1px;
    padding: 0.5rem;
    width: 6rem;
    // max-width: 6rem;
    border-radius: 0.2rem;
    &.active {
      // background: #ffd964;
    }
  }
  .week-rule {
    font-weight: 900;
  }
  .vws-time-rule {
    width: 100px;
    display: block ruby;
    font-weight: 900;
  }
  .vws-rule-time-week,
  .vws-rule-time-item {
    cursor: pointer;
  }
  .vws-rule-time-week,
  .vws-rule-time-item {
    background: #efefef;
  }
  .vws-rule-time-week.active,
  .vws-rule-time-item.active {
    background: #ffd964;
  }
  .vws-rule-time-week:hover,
  .vws-rule-time-item:hover {
    background: #ffd964;
  }
  .vws-rule-time-item span {
    display: none;
  }
  .vws-rule-time-item .parent {
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    font-size: 13px;
    font-weight: 800;
    height: 4px;
  }
  .vws-rule-time-item.active span {
    display: block;
    text-align: center;
    color: #000;
    // background: #ffd964;
  }
  .vws-rule-time-week.active.default,
  .vws-rule-time-item.active.default,
  .vws-rule-time-item.active.default span {
    background: #fb7676;
    color: #fff;
    cursor: default;
    pointer-events: none;
  }
  .vws-rule-time-week.outRange,
  .vws-rule-time-item.outRange,
  .vws-rule-time-item.outRange span {
    background: #b8b0b0;
    color: #fff;
    cursor: default;
    pointer-events: none;
  }
  .vws-rule-time-week.outRange.default,
  .vws-rule-time-item.outRange.default,
  .vws-rule-time-item.outRange.default span {
    background: #b8b0b0;
    color: #fff;
    cursor: default;
    pointer-events: none;
  }
  .time_list:nth-child(4n + 1) {
    margin-bottom: 0.5rem;
  }
  .vws-rule-time-item.up,
  .vws-time-list.vws-rule-time-week {
    background: #21c3e7c7;
    &:hover {
      background: #259fba;
    }
  }
}
</style>
