<template>
  <div class="main input_form">
    <div class="left_data">
      <div class="d-flex flex-column align-items-start">
        <label class="red_star">* <span>課程狀態</span></label>
        <el-form-item prop="status">
          <el-radio-group v-model="$store.state.classForm.status">
            <el-radio label="Published">公開</el-radio>
            <el-radio label="Unpublished">非公開</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="d-flex flex-column align-items-start">
        <label class="red_star">* <span>課程等級</span></label>
        <el-form-item prop="degree">
          <el-radio-group v-model="$store.state.classForm.degree">
            <el-radio label="Beginner">入門</el-radio>
            <el-radio label="Elementary">初級</el-radio>
            <el-radio label="Intermediate">中級</el-radio>
            <el-radio label="Advance">中高級</el-radio>
            <el-radio label="Master">高級</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="d-flex flex-column align-items-start">
        <label class="red_star">* <span>加入體驗課</span></label>
        <el-form-item prop="isExperience">
          <el-radio-group v-model="$store.state.classForm.isExperience">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <el-form-item label="標籤顏色" prop="color">
        <el-color-picker v-model="$store.state.classForm.color" :predefine="predefineColors" />
      </el-form-item>
      <!-- <el-form-item prop="duration" label="課程長度" class="noRound duration">
        <div>
          <el-input-number controls-position="right" v-model="$store.state.classForm.duration" :min="1" />
          <span>&nbsp; /分鐘</span>
        </div>
      </el-form-item> -->
      <el-form-item prop="maxStudentCount" label="滿班人數" class="noRound maxStudentCount">
        <div>
          <el-input-number controls-position="right" v-model="$store.state.classForm.maxStudentCount" :min="1" />
          <span>&nbsp; /人</span>
        </div>
      </el-form-item>
      <Cascader />
      <div class="d-flex flex-column align-items-start">
        <el-form-item prop="dateRange" label="開課日期區間（最多五個月）" class="dateRange">
          <el-date-picker @change="dateChange" :disabledDate="disabledDateFun" @calendar-change="calendarChange" v-model="$store.state.classForm.dateRange" type="daterange" range-separator="至" start-placeholder="開始日期" end-placeholder="結束日期" />
          <el-button :disabled="btnDisabled" class="message" @click="showTeacherClassCal()" type="primary" round size="medium">開始選擇課程時間</el-button>
        </el-form-item>
      </div>
      <el-form-item prop="weekTime" label="開課時間" class="weekTime me-2"> </el-form-item>
      <TeacherClassCal v-if="isCalShow" :teacherId="$store.state.classForm.teacherId" />
    </div>
  </div>
</template>

<script>
import Cascader from "../../../../components/ChargeMeTribe/TeacherCenter/TeacherClass/Cascader.vue";
import TeacherClassCal from "../../../../components/ChargeMeTribe/TeacherCenter/TeacherClass/TeacherClassCal.vue";
const dayjs = require("dayjs");

export default {
  name: "ClassInformation",
  components: {
    Cascader,
    TeacherClassCal,
  },
  data() {
    return {
      props: { multiple: true },
      weekValue: [],
      timeValue: "",
      isCalShow: false,
      startDate: null,
      tempDate: [],
      btnDisabled: false,
      predefineColors: ["#ff4500", "#ff8c00", "#ffd700", "#90ee90", "#00ced1", "#1e90ff", "#c71585", "#FF4500", "#FF7800", "#FAD400", "#90F090", "#00BABD", "#1F93FF", "#c71585"],
    };
  },
  inject: ["source"],
  computed: {
    editData() {
      const vm = this;
      if (!vm.$store.state.classForm.isAddNewClass) {
        vm.getEditData(vm.source());
      }
      return vm.source();
    },
  },
  watch: {
    editData() {
      this.getEditData(this.editData);
    },
  },
  mounted() {
    const vm = this;
    vm.tempDate = vm.$store.state.classForm.dateRange;
    if (vm.$store.state.classForm.dateRange.length == 2 && vm.$store.state.classForm.dateRange[0] != null) {
      vm.btnDisabled = true;
      vm.showTeacherClassCal();
    }
  },
  methods: {
    dateChange(dates) {
      const vm = this;
      if (dates == null) dates = [];
      if (vm.tempDate == null) vm.tempDate = [];
      if (vm.tempDate != dates && vm.tempDate.length != 0) {
        vm.isCalShow = false;
        vm.$confirm(`日期區間已改變，原本資料在此新區間外會被刪除，請確認！`, "Warning", {
          confirmButtonText: "確認",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            // Delete Data
            let data = {
              startDateTime: dates.length == 0 ? new Date() : dates[0],
              endDateTime: dates.length == 0 ? new Date() : dates[1],
              isDeleteAll: dates.length == 0,
            };
            vm.fetchAPI("delete", `${process.env.VUE_APP_URL_API}/Courses/CourseTime/${vm.$store.state.classForm.courseId}`, data)
              .then(() => {
                vm.notify("success", "原本資料在此新區間外已刪除成功！");
              })
              .catch(err => {
                console.log(err);
                vm.notify("error", "刪除失敗！");
              })
              .finally(() => {
                vm.isCalShow = false;
                vm.tempDate = vm.$store.state.classForm.dateRange;
                vm.$store.state.classForm.dateRange = dates;
                vm.btnDisabled = false;
                vm.startDate = null;
              });
          })
          .catch(() => {
            vm.isCalShow = true;
            vm.$store.state.classForm.dateRange = vm.tempDate;
            return;
          });
      } else {
        vm.tempDate = dates;
        vm.$store.state.classForm.dateRange = dates;
      }

      if (dates.length == 0) vm.startDate = null;
    },
    calendarChange(dates) {
      const vm = this;
      let hasSelectDate = dates != null && dates.length > 0;
      vm.startDate = hasSelectDate ? dates[0] : null;
    },
    disabledDateFun(time) {
      const vm = this;
      if (vm.startDate != null) {
        return time.getTime() < vm.startDate.getTime() || time.getTime() > vm.startDate.getTime() + 152 * 8.64e7;
      }
    },
    getEditData(obj) {
      // console.log(obj);
      if (obj == undefined || Object.keys(obj).length == 0) return;
      const vm = this;
      vm.$store.state.classForm.status = obj.status;
      vm.$store.state.classForm.isExperience = obj.isExperience;
      vm.$store.state.classForm.color = obj.color;
      vm.$store.state.classForm.degree = obj.degree;
      vm.$store.state.classForm.duration = obj.duration;
      vm.$store.state.classForm.maxStudentCount = obj.maxStudentCount;
      vm.$store.state.classForm.instrumentId = obj.instrumentId;
      vm.$store.state.classForm.teachingTypes = obj.teachingTypes;
      vm.$store.state.classForm.regions = obj.regions.map(x => Number(x));
      vm.$store.state.classForm.dateRange = obj.courseOpenedTimeInfo.startTime != null ? [new Date(obj.courseOpenedTimeInfo.startTime), new Date(obj.courseOpenedTimeInfo.endTime)] : [];
      vm.$store.state.classForm.courseOpenedTimeInfo.availableWeekList = obj.courseOpenedTimeInfo.availableWeekList;
    },
    async showTeacherClassCal() {
      const vm = this;
      // console.log(vm.tempDate, vm.$store.state.classForm.dateRange);
      if (vm.$store.state.classForm.dateRange.length == 2) {
        vm.btnDisabled = true;
        let sd = dayjs(vm.$store.state.classForm.dateRange[0]).format("YYYY-MM-DD");
        let ed = dayjs(vm.$store.state.classForm.dateRange[1]).format("YYYY-MM-DD");
        await vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/teacher/${vm.$store.state.classForm.teacherId}/calendar?startDate=${sd}&endDate=${ed}`).then(res => {
          vm.$store.state.classForm.courseTimeEntities = [];
          res.data.forEach(x => {
            vm.$store.state.classForm.courseTimeEntities.push({
              datetime: new Date(x.start),
              title: x.id != vm.$store.state.classForm.courseId ? x.title : "✓",
              teacherId: vm.$store.state.classForm.teacherId,
              courseId: x.courseId,
              courseTimeId: x.courseTimeId,
              dateIndex: x.dateIndex,
              timeIndex: x.timeIndex,
              isRemove: false,
            });
          });
          // console.log(vm.$store.state.classForm.courseTimeEntities);
        });
        vm.isCalShow = true;
      } else vm.notify("warning", "請先選擇日期區間！");
    },
  },
};
</script>

<style lang="scss" scoped>
:deep .noRound .el-input__wrapper {
  border-radius: 0;
}
.weekTime {
  margin-bottom: 0;
  :deep .el-form-item__content {
    flex-direction: row;
    align-items: center;
  }
  :deep .el-cascader {
    flex-grow: 1;
    margin-bottom: 0.5rem;
  }
}
.weekTimes {
  text-align: start;
}
.red_star {
  font-size: 1.1rem;
  font-weight: 900;
  span {
    font-size: 1.1rem;
    font-weight: 900;
    color: rgba(0, 0, 0, 0.55);
  }
}
.dateRange {
  :deep .el-form-item__content {
    flex-direction: row;
    align-items: center;
  }
  button {
    margin-left: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .main {
    padding-bottom: 5rem;
  }
  .weekTime {
    :deep .el-cascader {
      margin-bottom: 0;
    }
  }
}
</style>
