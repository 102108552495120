<template>
  <div class="main input_form">
    <div class="left_data">
      <div class="d-flex flex-column align-items-start">
        <label class="red_star">* <span>課程名稱</span></label>
        <el-form-item class="w-100" prop="name">
          <el-input v-model="$store.state.classForm.name" placeholder="請輸入課程名稱" />
        </el-form-item>
      </div>
      <div class="d-flex flex-column align-items-start">
        <label class="red_star">* <span>課程封面圖片</span></label>
        <el-form-item prop="cover" class="cover_section">
          <div class="cover w-100">
            <el-upload class="avatar-uploader" action="" :http-request="uploadCover" :show-file-list="false" :on-success="handleAvatarSuccess">
              <img v-if="$store.state.classForm.cover" :src="$store.state.classForm.cover" class="avatar" />
              <i class="fa-solid fa-plus avatar-uploader-icon" v-else></i>
            </el-upload>
          </div>
        </el-form-item>
      </div>
      <el-form-item prop="introVideo" label="課程影片">
        <el-input v-model="$store.state.classForm.introVideo" placeholder="請輸入Youtube連結" />
      </el-form-item>
      <el-form-item prop="introduction" label="課程簡介">
        <el-input v-model="$store.state.classForm.introduction" placeholder="請輸入課程簡介" type="textarea" :autosize="{ minRows: 5 }" />
      </el-form-item>
      <el-form-item prop="detail" label="課程詳細內容">
        <Editor :value="$store.state.classForm.detail" @input="getEditorData" />
      </el-form-item>
    </div>
  </div>
</template>

<script>
import Editor from "../../../Common/Editor.vue";

export default {
  name: "ClassContent ",
  components: {
    Editor,
  },
  inject: ["source"],
  computed: {
    editData() {
      const vm = this;
      if (!vm.$store.state.classForm.isAddNewClass) {
        vm.getEditData(vm.source());
      }
      return vm.source();
    },
  },
  watch: {
    editData() {
      this.getEditData(this.editData);
    },
  },
  methods: {
    uploadCover(param) {
      const vm = this;
      if (!vm.imageRestrict(param)) {
        vm.notify("error", "圖片格式不支援，僅限 jpg / jpeg / png！");
        return;
      }
      let formData = new FormData();
      formData.append("image", param.file);
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/images/image`, formData)
        .then(res => {
          this.notify("success", "封面圖片上傳成功！");
          this.$store.state.classForm.cover = res.data;
        })
        .catch(err => {
          console.log(err);
          this.notify("error", "封面圖片上傳失敗！");
        });
    },
    getEditorData(input) {
      this.$store.state.classForm.detail = input;
    },
    getEditData(obj) {
      if (obj == undefined || Object.keys(obj).length == 0) return;
      const vm = this;
      vm.$store.state.classForm.name = obj.name;
      vm.$store.state.classForm.cover = obj.cover;
      vm.$store.state.classForm.introVideo = obj.introVideo;
      vm.$store.state.classForm.detail = obj.detail;
      vm.$store.state.classForm.introduction = obj.introduction;
    },
  },
};
</script>

<style lang="scss" scoped>
.cover_section {
  width: 100%;
  :deep .el-form-item__content {
    align-items: center;
  }
}
:deep .avatar-uploader {
  display: flex;
  justify-content: center;
  .el-upload {
    width: 100%;
    height: 15rem;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin: auto;
    &:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 18rem;
      height: 18rem;
      line-height: 18rem;
      text-align: center;
    }
    img {
      object-fit: cover;
      width: 100%;
    }
  }
}
.red_star {
  font-size: 1.1rem;
  font-weight: 900;
  span {
    font-size: 1.1rem;
    font-weight: 900;
    color: rgba(0, 0, 0, 0.55);
  }
}
@media screen and (min-width: 768px) {
  .main {
    padding-bottom: 5rem;
  }
  :deep .avatar-uploader {
    .el-upload {
      height: 30rem;
    }
  }
}
</style>
