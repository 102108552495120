<template>
  <el-form-item prop="instrumentId">
    <div class="label_icon">
      <label class="red_star">* <span>教學科目</span></label>
      <el-popover placement="top-start" :width="200" trigger="hover" content="如果未找到您的教學科目，請至老師主頁新增可教學科目！">
        <template #reference>
          <i class="fa-solid fa-circle-exclamation"></i>
        </template>
      </el-popover>
    </div>
    <el-cascader v-model="$store.state.classForm.instrumentId" :options="form_options.instrument" :props="props" clearable placeholder="請選擇可教學樂器"></el-cascader>
  </el-form-item>
  <el-form-item prop="teachingTypes">
    <div class="label_icon">
      <label class="red_star">* <span>課程類型</span></label>
      <el-popover placement="top-start" :width="200" trigger="hover" content="如果未找到您的課程類型，請至老師主頁新增可課程類型！">
        <template #reference>
          <i class="fa-solid fa-circle-exclamation"></i>
        </template>
      </el-popover>
    </div>
    <el-cascader v-model="$store.state.classForm.teachingTypes" :options="form_options.teachingType" :props="{ multiple: true }" clearable placeholder="請選擇可課程類型"></el-cascader>
  </el-form-item>
  <el-form-item prop="regions">
    <div class="label_icon">
      <label class="red_star">* <span>教學地點</span></label>
      <el-popover placement="top-start" :width="200" trigger="hover" content="如果未找到您的教學地點，請至老師主頁新增可教學地點！">
        <template #reference>
          <i class="fa-solid fa-circle-exclamation"></i>
        </template>
      </el-popover>
    </div>
    <el-cascader v-model="$store.state.classForm.regions" :options="form_options.teachingArea" :props="{ multiple: true }" clearable placeholder="請選擇可教學地點"></el-cascader>
  </el-form-item>
</template>

<script>
export default {
  name: "ClassInformation",
  data() {
    return {
      form_options: {
        teachingType: this.$store.state.common.teachingTypeOptions,
        instrument: [],
        teachingArea: [],
      },
      form_values: {
        teachingType: [],
        instrument: "",
        teachingArea: "",
      },
    };
  },
  async created() {
    const vm = this;
    await vm.getInstrument("teacherClass");
    await vm.getDistrict();
    await vm.getInfo();
  },
  methods: {
    getDistrict() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/District`)
        .then(res => {
          if (res.data.length !== 0) {
            res.data.forEach(item => {
              let temp = [];
              let arr = [];
              Object.assign(arr, item.district);
              arr.forEach(x => {
                temp.push({ value: x.id, label: x.districtName });
              });
              vm.form_options.teachingArea.push({
                label: item.county,
                children: temp,
              });
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getInfo() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/teacher/${vm.$store.state.auth.user.id}/info`)
        .then(res => {
          vm.form_values = {
            teachingType: res.data.teachingType,
            instrument: res.data.instrument,
            teachingArea: res.data.teachingArea,
          };
          vm.turnType();
        })
        .catch(err => {
          console.log(err);
        });
    },
    turnType() {
      const vm = this;
      // 樂器
      vm.form_options.instrument.forEach((x, i) => {
        let arr = x.children.filter(item => vm.form_values.instrument.includes(item.value));
        vm.form_options.instrument[i].children = arr;
        if (arr.length == 0) {
          vm.form_options.instrument[i].disabled = true;
        }
      });
      // 地區
      vm.form_options.teachingArea.forEach((x, i) => {
        let arr = x.children.filter(item => vm.form_values.teachingArea.split(",").includes(item.value.toString()));
        vm.form_options.teachingArea[i].children = arr;
        if (arr.length == 0) {
          vm.form_options.teachingArea[i].disabled = true;
        }
      });
      // 類型
      vm.form_options.teachingType = vm.form_options.teachingType.filter(item => vm.form_values.teachingType.split(",").includes(item.value));
    },
  },
};
</script>

<style lang="scss" scoped>
:deep .el-cascader {
  width: 100%;
}
.red_star {
  color: red !important;
  span {
    font-size: 1.1rem;
    font-weight: 900;
    color: rgba(0, 0, 0, 0.55);
  }
}
</style>
