<template>
  <div class="main input_form">
    <div class="left_data">
      <div class="d-flex flex-column align-items-start">
        <label class="red_star">* <span>課程費用</span></label>
        <el-form-item prop="hourlyFee" class="hourlyFee">
          <el-input class="" v-model="$store.state.classForm.price" @input="$store.state.classForm.price = turnNumber($store.state.classForm.price)"></el-input>
          <span>&nbsp; /元</span>
        </el-form-item>
        <el-form-item class="priceRemark w-100">
          <el-input v-model="$store.state.classForm.priceRemark" placeholder="請輸入課程費用備註" type="textarea" :autosize="{ minRows: 10 }" />
        </el-form-item>
      </div>
      <el-form-item prop="payDiscount" label="收費折扣" class="payDiscount">
        <div class="d-flex">
          <div class="d-flex align-items-center">
            <span>堂數：</span>
            <el-input class="w-50" v-model="classCount" @input="classCount = turnNumber(classCount)" placeholder="請輸入課程堂數" />
            <span>&nbsp; /堂</span>
          </div>
          <div class="d-flex align-items-center">
            <span>折扣：</span>
            <el-input class="w-50" v-model="classDiscount" @input="classDiscount = turnNumberDiscount(classDiscount)" placeholder="請輸入課程折扣" />
            <span>&nbsp; %</span>
          </div>
          <el-button class="message" @click="addDiscount()" type="primary" round size="medium">新增</el-button>
        </div>
        <span class="discountShow">即時金額：{{ discountPrice }} /元</span>
      </el-form-item>
      <div class="discountShowArea">
        <el-tag v-for="tag in $store.state.classForm.payDiscount" :key="tag" type="warning" size="large" class="mx-1" effect="dark" closable @close="handleClose(tag)">
          {{ tag }}
        </el-tag>
      </div>
      <el-form-item prop="chargeMethod" label="收費方式" class="chargeMethod">
        <el-radio-group v-model="$store.state.classForm.payPeriod">
          <el-radio label="日繳 (1堂)">日繳 (1堂)</el-radio>
          <el-radio label="月繳 (4堂)">月繳 (4堂)</el-radio>
          <el-radio label="季繳 (12堂)">季繳 (12堂)</el-radio>
          <el-radio label="半年繳 (24堂)">半年繳 (24堂)</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="payMethod" label="付款方式">
        <el-checkbox-group v-model="$store.state.classForm.payMethod">
          <el-checkbox label="現金">現金</el-checkbox>
          <el-checkbox label="匯款">匯款</el-checkbox>
          <el-checkbox label="線上付款 (line pay)">線上付款 (line pay)</el-checkbox>
          <el-checkbox label="線上付款 (街口)">線上付款 (街口)</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClassPayment",
  inject: ["source"],
  data() {
    return {
      classCount: 0,
      classDiscount: 0,
    };
  },
  computed: {
    editData() {
      const vm = this;
      if (!vm.$store.state.classForm.isAddNewClass) {
        vm.getEditData(vm.source());
      }
      return vm.source();
    },
    discountPrice() {
      const vm = this;
      return Math.round(vm.$store.state.classForm.price * (vm.classDiscount / 100));
    },
  },
  watch: {
    editData() {
      this.getEditData(this.editData);
    },
  },
  methods: {
    getEditData(obj) {
      if (obj == undefined || Object.keys(obj).length == 0) return;
      const vm = this;
      vm.$store.state.classForm.price = obj.price;
      vm.$store.state.classForm.priceRemark = obj.priceRemark;
      vm.$store.state.classForm.payDiscount = obj.payDiscount;
      vm.$store.state.classForm.payPeriod = obj.payPeriod;
      vm.$store.state.classForm.payMethod = obj.payMethod.split(",");
    },
    addDiscount() {
      const vm = this;
      if (vm.classCount == 0 || vm.classDiscount == 0) {
        vm.notify("warning", "折扣堂數以及折扣趴數不可為零！");
        return;
      }

      var isAlreadyAdd = false;
      vm.$store.state.classForm.payDiscount.forEach(x => {
        if (Number(x.split("/堂")[0]) == vm.classCount) {
          vm.notify("warning", "折扣堂數已新增過！");
          isAlreadyAdd = true;
          return;
        }
      });
      if (isAlreadyAdd) return;

      vm.$store.state.classForm.payDiscount.push(`${vm.classCount}/堂 ${vm.classDiscount}%/折扣`);
      vm.$store.state.classForm.payDiscount.sort(function (a, b) {
        return Number(a.split("/堂")[0]) - Number(b.split("/堂")[0]);
      });
      vm.classCount = 0;
      vm.classDiscount = 0;
    },
    handleClose(tag) {
      this.$store.state.classForm.payDiscount.splice(this.$store.state.classForm.payDiscount.indexOf(tag), 1);
    },
    turnNumberDiscount(x) {
      var result = this.turnNumber(x);
      var reg = new RegExp("^(\\d|[1-9]\\d|99)$");
      if (!reg.test(result)) return 99;
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.hourlyFee {
  :deep .el-form-item__content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    .el-input__inner {
      text-align: center;
      width: 100%;
    }
    span {
      width: 3rem;
      font-size: 1rem;
    }
  }
}
.payDiscount {
  margin-bottom: 0;
  padding-bottom: 0;
  :deep .el-form-item__content {
    .el-input__inner {
      text-align: center;
      width: 100%;
    }
    span {
      font-size: 1rem;
    }
    .discountShow {
      font-weight: 900;
      margin: 1rem 0;
      color: rgb(76, 76, 234);
    }
  }
}
.chargeMethod {
  .other {
    width: 100%;
  }
}
.el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
}
.fixedFee {
  :deep .el-input__wrapper {
    border-radius: 0;
  }
}
.red_star {
  font-size: 1.1rem;
  font-weight: 900;
  span {
    font-size: 1.1rem;
    font-weight: 900;
    color: rgba(0, 0, 0, 0.55);
  }
}
.discountShowArea {
  text-align: start;
  margin-bottom: 2rem;
  .el-tag {
    margin-bottom: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .main {
    padding-bottom: 5rem;
  }
}
</style>
